import React, { useState, useEffect, useContext } from "react";
import UserDetailModal from "./common/userDetailModal";
import dayjs from "dayjs";
import SalesOverviewTableSummary from "./salesOverviewTableSummary";
import { formatAmount, getCurrency } from "../utils/helper";
import { vendorsList } from "../lib/config";
import { TableVirtuoso } from "react-virtuoso";
import { Tooltip } from "@material-ui/core";
import { DailySalesDataContext } from "./dashboard";

const DailySalesReportSummary = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  dailySalesData,
  salesOverview,
  setSalesOverview,
  getAverageOrderValue,
  dashboardUpdatedAt,
  refreshDataBtn,
  showOfflineSales,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [orderDate, setOrderDate] = useState();

  const [sortedVendordCodes, setSortedVendorCodes] = useState([]);

  const {
   visibleBrands
  } = useContext(DailySalesDataContext);

  useEffect(() => {
    if (salesOverview) {
      const brands = Object.keys(salesOverview);
      const filteredBrands = brands?.filter(
        (brand) =>
          salesOverview[brand]?.orderCount > 0 && visibleBrands?.includes(brand)
      );
      setSortedVendorCodes(
        filteredBrands?.sort(function (a, b) {
          return salesOverview[b]?.orderCount - salesOverview[a]?.orderCount;
        })
      );
    }
  }, [salesOverview, visibleBrands]);

  const openModal = (device, date) => {
    setIsOpen(true);
    setDeviceType(device);
    setOrderDate(date);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      minHeight: "200px",
    },
    overlay: { zIndex: 1000 },
  };

  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    setAnimated(false);
  }, [salesOverview, GMV]);

  return (
    <div className="stats desktop-only">
      <h2>Daily Sales Report</h2>
      <div className="updated-at">
        <h5 className="text-muted">
          Updated at: {dashboardUpdatedAt?.toLocaleTimeString("en-US")}
        </h5>
        <img
          className="refresh-icon"
          src="/images/recurring-appointment-32.png"
          alt="arrow"
          onClick={refreshDataBtn}
        />
      </div>
      <UserDetailModal
        modalIsOpenMobile={modalIsOpen}
        closeModalMobile={closeModal}
        deviceType={deviceType}
        orderDate={orderDate}
        customStylesMobile={customStyles}
        showOfflineSales={showOfflineSales}
        vendorCode={deviceType?.grand.vendorCode}
      />
      {dailySalesData && visibleBrands?.length > 0 ? (
        <>
          <TableVirtuoso
            style={{ minHeight: "90vh" }}
            totalCount={dailySalesData.length + 1}
            components={{
              Table: ({ style, ...props }) => (
                <table {...props} style={{ ...style }} />
              ),
            }}
            fixedHeaderContent={() => (
              <>
                <tr className="thDefault">
                  <th
                    className="brand"
                    style={{
                      width: 150,
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                    }}
                  >
                    Brand
                  </th>
                  {sortedVendordCodes?.length > 0 &&
                    sortedVendordCodes.map((vendorCode, key) => {
                      const vendor = vendorsList.find(
                        (vendor) => vendor?.VENDOR_CODE === vendorCode
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            colSpan="2"
                            className={`${vendor.VENDOR_CODE}Light`}
                          >
                            <div className="vendors-list">
                              <img src={vendor?.FAV_ICON} alt="logo" />
                              <h4 className={`${vendor.VENDOR_CODE}`}>
                                {vendor?.TITLE}
                              </h4>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                </tr>
                <tr className="OrdRev text-right">
                  <th
                    className="channelLight"
                    style={{
                      width: 150,
                      position: "sticky",
                      left: 0,
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Date
                  </th>
                  {sortedVendordCodes?.length > 0 &&
                    sortedVendordCodes.map((brand, key) => (
                      <React.Fragment key={key}>
                        <th colSpan="1" className={`text-right ${brand}Dark`}>
                          <span className="text-right">Orders</span>
                        </th>
                        <th
                          className={`text-right ${brand}Dark`}
                          colSpan="1"
                          style={{
                            paddingLeft: "60px",
                            paddingRight: "20px",
                          }}
                        >
                          <span className="text-right">Revenue</span>
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </>
            )}
            itemContent={(index) => {
              return index === 0 ? (
                <SalesOverviewTableSummary
                  GMV={GMV}
                  getOrderAmount={getOrderAmount}
                  salesOverview={salesOverview}
                  getAverageOrderValue={getAverageOrderValue}
                  animated={animated}
                  setAnimated={setAnimated}
                />
              ) : (
                <>
                  <td
                    className="text-center"
                    style={{
                      width: 150,
                      background: "#D3D3D3",
                      position: "sticky",
                      left: 0,
                    }}
                  >
                    {dayjs(dailySalesData[index - 1]?.date).format("ddd,D MMM")}
                  </td>

                  {sortedVendordCodes?.length > 0 &&
                    sortedVendordCodes.map((vendorCode, key) => {
                      const currentVendor = dailySalesData[
                        index - 1
                      ]?.sales.find((b) => b.grand?.vendorCode === vendorCode);
                      return (
                        <React.Fragment key={key}>
                          <td
                            className={`${
                              currentVendor ? "select-orders" : ""
                            } ${vendorCode}Light text-right`}
                            onClick={() =>
                              currentVendor
                                ? openModal(
                                    currentVendor,
                                    dailySalesData[index - 1]?.date
                                  )
                                : false
                            }
                          >
                            <span
                              className={`text-right font-bold 
                              ${currentVendor ? "orderCount" : ""}`}
                            >
                              {showOfflineSales ? (
                                <Tooltip
                                  title={
                                    currentVendor?.grand
                                      ? `AOV: ${getAverageOrderValue(
                                          currentVendor?.grand,
                                          GMV,
                                          vendorCode
                                        )}`
                                      : ""
                                  }
                                  arrow
                                >
                                  <span>
                                    {currentVendor?.grand?.orderCount?.toLocaleString(
                                      "en-IN"
                                    ) || "-"}
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={
                                    currentVendor?.online
                                      ? `AOV: ${getAverageOrderValue(
                                          currentVendor?.online,
                                          GMV,
                                          vendorCode
                                        )}`
                                      : ""
                                  }
                                  arrow
                                >
                                  <span>
                                    {currentVendor?.online?.orderCount?.toLocaleString(
                                      "en-IN"
                                    ) || "-"}
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </td>

                          <td className={`text-right ${vendorCode}Light`}>
                            {showOfflineSales ? (
                              <>
                                {currentVendor?.grand
                                  ? formatAmount(
                                      getOrderAmount(currentVendor.grand, GMV),
                                      getCurrency(vendorCode)
                                    )
                                  : "-"}
                                <>
                                  {currentVendor?.grand?.raiseAlert ? (
                                    <Tooltip
                                      title="GMV seems to be higher than usual, please
                                    deep dive into orders."
                                      arrow
                                    >
                                      <img
                                        src="images/warning-sign.svg"
                                        alt="warning"
                                        style={{
                                          marginLeft: "2px",
                                          width: "15px",
                                          height: "12px",
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </>
                              </>
                            ) : (
                              <>
                                {currentVendor?.online
                                  ? formatAmount(
                                      getOrderAmount(currentVendor.online, GMV),
                                      getCurrency(vendorCode)
                                    )
                                  : "-"}

                                {currentVendor?.online?.raiseAlert ? (
                                  <Tooltip
                                    title="GMV seems to be higher than usual, please
                                    deep dive into orders."
                                  >
                                    <img
                                      src="images/warning-sign.svg"
                                      alt="warning"
                                      style={{
                                        marginLeft: "2px",
                                        width: "15px",
                                        height: "12px",
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </td>
                        </React.Fragment>
                      );
                    })}
                </>
              );
            }}
          />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <img className="no-data" src="/images/no-data.png" alt="no data" />
        </div>
      )}
    </div>
  );
};

export default DailySalesReportSummary;
